import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Box, Button, Container, Grid, Heading, Paragraph } from "theme-ui"
import { navigate } from "@reach/router"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => {
  const joinHandler = () => {
    navigate("https://discord.gg/razer")
  }
  return (
    <Layout>
      <Seo title="404" />
      <Grid
        sx={{
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <Box
          sx={{
            position: "relative",
            bg: "background",
            p: 4,
            borderRadius: 5,
            textAlign: "center",
            maxWidth: 540,
          }}
        >
          <StaticImage
            src="../images/snekiCry.png"
            placeholder="tracedSVG"
            quality={90}
            formats={["auto", "webp", "avif"]}
            alt="Vampire Sneki Snek"
            width={240}
          />
          <Heading as="h1" mt={3}>
            Oh no!
          </Heading>
          <Paragraph>
            Sneki Snek has ventured too far into the wild and got lost...
          </Paragraph>
          <Button variant="fullWidth" mt={4} onClick={joinHandler}>
            Back to homepage
          </Button>
        </Box>
      </Grid>
    </Layout>
  )
}

export default IndexPage
